import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'rqc';
import { environment } from '../../../../environments/environment';
import { LoggerType, LogType } from '../enums/log-type.enum';
import { NotificationType } from '../enums/notification-type.enum';
import { LogViewModel } from '../models/log.model';
import { NotificationService } from '../../share/services/notification.service';
import { LogsDataService } from '../../server/services/infrastructure/logs-data.service';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	public suppressDisplayOfErrors = false;

	constructor(private logsDataService: LogsDataService, private notificationService: NotificationService) {}

	public log(log: LogViewModel, logTypes: Array<LoggerType> = [LoggerType.Api, LoggerType.Frontend, LoggerType.Console]) {
		logTypes.forEach(logType => {
			switch (logType) {
				case LoggerType.Console:
					this.logInConsole(log);
					break;
				case LoggerType.Api:
					this.logInApi(log);
					break;
				case LoggerType.Frontend:
					if (!this.suppressDisplayOfErrors) {
						this.logInFronted(log);
					}

					break;
				default:
					throw new Error(`${logType} is not handled`);
			}
		});
	}

	public logDev(log: LogViewModel) {
		if (!environment.production) {
			log.type = isNullOrUndefined(log.type) ? LogType.Warning : log.type;

			this.log(log, [LoggerType.Console, LoggerType.Frontend]);
		}
	}

	public logInConsole(log: LogViewModel) {
		/* eslint-disable no-console */
		switch (log.type) {
			case LogType.Warning:
				console.warn(log.message);
				break;
			case LogType.Error:
				console.error(log.message);
				console.error(log.stacktrace);
				break;
			default:
				console.log(log.message);
				break;
		}
		/* eslint-enable no-console */
	}

	public logInApi(log: LogViewModel) {
		this.logsDataService.post({ message: log.message, stacktrace: log.stacktrace ? log.stacktrace : '' });
	}

	public logInFronted(log: LogViewModel) {
		this.notificationService.showToastNotification({
			type: NotificationType.Error,
			title: log.message,
			message: log.stacktrace ? log.stacktrace : ''
		});
	}
}
