import { Injectable } from '@angular/core';
import { BaseHubService } from './base-hub.service';
import { HUB } from '../configs/hub.config';
import { LoggerService } from 'rqc';
import { NotificationHubModel } from '../../share/models/notification-hub.model';

@Injectable({
	providedIn: 'root'
})
export class NotificationHubService extends BaseHubService {
	constructor(loggerService: LoggerService) {
		super(HUB.notification.path, loggerService);
	}

	public statusChange(handler: (status: NotificationHubModel) => void) {
		this.connection?.on('statusChange', handler);
	}
}
