import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ThirdPartyDefinitionModel } from '../../models/third-party-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ThirdPartyFilterModel } from '../../models/third-party-filter-model.model';
import { ThirdPartyModel } from '../../models/third-party-model.model';
import { ThirdPartyCreateBaseModel } from '../../models/third-party-create-base-model.model';
import { ThirdPartyUpdateModel } from '../../models/third-party-update-model.model';
import { TpCreateViewModel } from '../../models/tp-create-view-model.model';

@Injectable()
export class ThirdPartyDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: ThirdPartyFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ThirdPartyDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ThirdPartyDefinitionModel>>('get', environment.api + '/api/TPConfig/ThirdParty/GetPaginated', options)
			.toPromise();
	}

	public getById(thirdPartyId: string, options = new HttpOptionsViewModel()): Promise<ThirdPartyModel> {
		options.params = this.httpService.getHttpParams({
			thirdPartyId
		});

		return this.httpService.request<ThirdPartyModel>('get', environment.api + '/api/TPConfig/ThirdParty/GetById', options).toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<ThirdPartyModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService.request<ThirdPartyModel>('get', environment.api + '/api/TPConfig/ThirdParty/GetByName', options).toPromise();
	}

	public createBase(createModel: ThirdPartyCreateBaseModel, options = new HttpOptionsViewModel()): Promise<ThirdPartyModel> {
		options.body = createModel;

		return this.httpService.request<ThirdPartyModel>('post', environment.api + '/api/TPConfig/ThirdParty/CreateBase', options).toPromise();
	}

	public update(updateModel: ThirdPartyUpdateModel, options = new HttpOptionsViewModel()): Promise<ThirdPartyModel> {
		options.body = updateModel;

		return this.httpService.request<ThirdPartyModel>('post', environment.api + '/api/TPConfig/ThirdParty/Update', options).toPromise();
	}

	public create(createModel: TpCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = createModel;

		return this.httpService.request<string>('post', environment.api + '/api/TPConfig/ThirdParty/Create', options).toPromise();
	}
}
