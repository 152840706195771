import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisLegalEntityDefinitionModel } from '../../models/analysis-legal-entity-definition-model.model';
import { AnalysisPortfolioLegalEntityModel } from '../../models/analysis-portfolio-legal-entity-model.model';
import { AnalysisLegalEntityModel } from '../../models/analysis-legal-entity-model.model';

@Injectable()
export class AnalysisLegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getByAnalysisId(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisLegalEntityDefinitionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisLegalEntityDefinitionModel>('get', environment.api + '/api/Analysis/AnalysisLegalEntity/GetByAnalysisId', options)
			.toPromise();
	}

	public getByUser(options = new HttpOptionsViewModel()): Promise<AnalysisPortfolioLegalEntityModel[]> {
		return this.httpService
			.request<AnalysisPortfolioLegalEntityModel[]>('get', environment.api + '/api/Analysis/AnalysisLegalEntity/GetByUser', options)
			.toPromise();
	}

	public getById(analysisLegalEntityId: string, options = new HttpOptionsViewModel()): Promise<AnalysisLegalEntityModel> {
		options.params = this.httpService.getHttpParams({
			analysisLegalEntityId
		});

		return this.httpService
			.request<AnalysisLegalEntityModel>('get', environment.api + '/api/Analysis/AnalysisLegalEntity/GetById', options)
			.toPromise();
	}
}
