import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { IntegrationArcherSettingsCreateModel } from '../../models/integration-archer-settings-create-model.model';
import { IntegrationArcherSettingsModel } from '../../models/integration-archer-settings-model.model';

@Injectable()
export class IntegrationArcherSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public create(model: IntegrationArcherSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationArcherSettings/Create', options)
			.toPromise();
	}

	public update(model: IntegrationArcherSettingsModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('put', environment.api + '/api/Integration/IntegrationArcherSettings/Update', options)
			.toPromise();
	}

	public getByIntegrationSettingId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationArcherSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationArcherSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationArcherSettings/GetByIntegrationSettingId',
				options
			)
			.toPromise();
	}
}
