import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { EnterpriseCustomVariableDefinitionViewModel } from '../../models/enterprise-custom-variable-definition-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { CustomVariableFilterViewModel } from '../../models/custom-variable-filter-view-model.model';
import { CustomVariableFilterOptionViewModel } from '../../models/custom-variable-filter-option-view-model.model';
import { CustomVariableViewModel } from '../../models/custom-variable-view-model.model';
import { CustomVariableUpdateViewModel } from '../../models/custom-variable-update-view-model.model';
import { CustomVariableCreateViewModel } from '../../models/custom-variable-create-view-model.model';
import { ActionResult } from '../../models/action-result.model';

@Injectable()
export class EnterpriseCustomVariablesDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		paginationModel: PaginationFilter,
		filter: CustomVariableFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseCustomVariableDefinitionViewModel>> {
		options.params = this.httpService.getHttpParams({
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<EnterpriseCustomVariableDefinitionViewModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseCustomVariables/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilterOptions(options = new HttpOptionsViewModel()): Promise<CustomVariableFilterOptionViewModel> {
		return this.httpService
			.request<CustomVariableFilterOptionViewModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseCustomVariables/GetFilterOptions',
				options
			)
			.toPromise();
	}

	public getById(lossVariableId: string, options = new HttpOptionsViewModel()): Promise<CustomVariableViewModel> {
		options.params = this.httpService.getHttpParams({
			lossVariableId
		});

		return this.httpService
			.request<CustomVariableViewModel>('get', environment.api + '/api/Enterprise/EnterpriseCustomVariables/GetById', options)
			.toPromise();
	}

	public isUniqueName(variableName: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			variableName
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Enterprise/EnterpriseCustomVariables/IsUniqueName', options)
			.toPromise();
	}

	public update(update: CustomVariableUpdateViewModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = update;

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseCustomVariables/Update', options).toPromise();
	}

	public create(createModel: CustomVariableCreateViewModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Enterprise/EnterpriseCustomVariables/Create', options)
			.toPromise();
	}

	public delete(lossVariableId: string, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.params = this.httpService.getHttpParams({
			lossVariableId
		});

		return this.httpService
			.request<ActionResult>('delete', environment.api + '/api/Enterprise/EnterpriseCustomVariables/Delete', options)
			.toPromise();
	}

	public clone(lossVariableId: string, clonedLossVariableName: string, options = new HttpOptionsViewModel()): Promise<string> {
		options.params = this.httpService.getHttpParams({
			lossVariableId,
			clonedLossVariableName
		});

		return this.httpService
			.request<string>('post', environment.api + '/api/Enterprise/EnterpriseCustomVariables/Clone', options)
			.toPromise();
	}
}
