import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { DefensebilityDeltaViewModel } from '../../models/defensebility-delta-view-model.model';
import { DefensebilityControlFilterViewModel } from '../../models/defensebility-control-filter-view-model.model';
import { ControlEvidenceViewModel } from '../../models/control-evidence-view-model.model';

@Injectable()
export class ControlDefensebilityDataService {
	constructor(private httpService: RQHttpService) {}

	public getDelta(input: DefensebilityControlFilterViewModel, options = new HttpOptionsViewModel()): Promise<DefensebilityDeltaViewModel> {
		options.params = this.httpService.getHttpParams({
			input
		});

		return this.httpService
			.request<DefensebilityDeltaViewModel>('get', environment.api + '/api/Configuration/ControlDefensebility/GetDelta', options)
			.toPromise();
	}

	public getCount(input: DefensebilityControlFilterViewModel[], options = new HttpOptionsViewModel()): Promise<ControlEvidenceViewModel[]> {
		options.body = input;

		return this.httpService
			.request<ControlEvidenceViewModel[]>('post', environment.api + '/api/Configuration/ControlDefensebility/GetCount', options)
			.toPromise();
	}
}
