import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ScenarioMitreBaViewModel } from '../../models/scenario-mitre-ba-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { MitreBusinessAssetFilterModel } from '../../models/mitre-business-asset-filter-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { MitreBusinessAssetUpdateModel } from '../../models/mitre-business-asset-update-model.model';

@Injectable()
export class ScenarioMitreBusinessAssetDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		scenarioId: string,
		pagination: PaginationFilter,
		filter: MitreBusinessAssetFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ScenarioMitreBaViewModel>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ScenarioMitreBaViewModel>>(
				'get',
				environment.api + '/api/Scenario/ScenarioMitreBusinessAsset/GetPaginated',
				options
			)
			.toPromise();
	}

	public update(
		updateModel: MitreBusinessAssetUpdateModel,
		filter: MitreBusinessAssetFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = updateModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMitreBusinessAsset/Update', options)
			.toPromise();
	}
}
