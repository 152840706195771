import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { FairMagnitudeValueModel } from '../../models/fair-magnitude-value-model.model';
import { FairMagnitudeValueUpdateModel } from '../../models/fair-magnitude-value-update-model.model';

@Injectable()
export class FairMagnitudeValueDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FairMagnitudeValueModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<FairMagnitudeValueModel[]>('get', environment.api + '/api/Fair/FairMagnitudeValue/GetByLegalEntityId', options)
			.toPromise();
	}

	public update(updateModel: FairMagnitudeValueUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('put', environment.api + '/api/Fair/FairMagnitudeValue/Update', options).toPromise();
	}

	public revertDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<void>('get', environment.api + '/api/Fair/FairMagnitudeValue/RevertDefaults', options).toPromise();
	}
}
