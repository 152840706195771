import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ImpactVectorRiskModel } from '../../models/impact-vector-risk-model.model';
import { Pagination } from '../../models/pagination.model';
import { AnalysisAggregatedThreatsModel } from '../../models/analysis-aggregated-threats-model.model';
import { AnalysisAggregatedThreatFilterModel } from '../../models/analysis-aggregated-threat-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class EnterpriseAggregatedThreatsDataService {
	constructor(private httpService: RQHttpService) {}

	public getRisk(
		analysisBusinessApplicationId: string,
		attackId: string,
		options = new HttpOptionsViewModel()
	): Promise<ImpactVectorRiskModel> {
		options.params = this.httpService.getHttpParams({
			analysisBusinessApplicationId,
			attackId
		});

		return this.httpService
			.request<ImpactVectorRiskModel>('get', environment.api + '/api/Enterprise/EnterpriseAggregatedThreats/GetRisk', options)
			.toPromise();
	}

	public getAnalysisAggregatedThreats(
		filterModel: AnalysisAggregatedThreatFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisAggregatedThreatsModel>> {
		options.params = this.httpService.getHttpParams({
			filterModel,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisAggregatedThreatsModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAggregatedThreats/GetAnalysisAggregatedThreats',
				options
			)
			.toPromise();
	}

	public export(options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		return this.httpService
			.request<Blob>('get', environment.api + '/api/Enterprise/EnterpriseAggregatedThreats/Export', options)
			.toPromise();
	}
}
