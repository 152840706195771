import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	public readonly refreshNavigationStates$: Observable<void>;

	private _refreshNavigationStates$ = new Subject<void>();

	private httpRequestList = new Map<string, boolean>();

	private readonly excludedUrl = [
		'/api/Configuration/LegalEntity/GetById',
		'/api/Configuration/LegalEntity/GetState',
		'/api/Enterprise/EnterpriseAnalysisLegalEntity/GetAllActive',
		'/api/Enterprise/EnterpriseCustomModels/GetPaginated',
		'/api/Enterprise/EnterpriseLookupTable/GetPaginated'
	];

	constructor() {
		this.refreshNavigationStates$ = this._refreshNavigationStates$.asObservable().pipe(debounceTime(1000));
	}

	public refreshStates(url: string, isNewRequest: boolean) {
		if (this.excludedUrl.some(x => url.includes(x))) {
			return;
		}

		if (!url) {
			throw new Error('The request URL must be provided');
		}
		if (isNewRequest === true) {
			this.httpRequestList.set(url, isNewRequest);
		} else if (isNewRequest === false && this.httpRequestList.has(url)) {
			this.httpRequestList.delete(url);
		}

		if (this.httpRequestList.size === 0) {
			this._refreshNavigationStates$.next();
		}
	}
}
