import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { LossExposureVulnerabilityModel } from '../../models/loss-exposure-vulnerability-model.model';
import { LossExposureVulnerabilityCreateModel } from '../../models/loss-exposure-vulnerability-create-model.model';
import { LossExposureVulnerabilityUpdateModel } from '../../models/loss-exposure-vulnerability-update-model.model';

@Injectable()
export class LossExposureVulnerabilityDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLossExposureId(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureVulnerabilityModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureVulnerabilityModel>(
				'get',
				environment.api + '/api/TPConfig/LossExposureVulnerability/GetByLossExposureId',
				options
			)
			.toPromise();
	}

	public create(createModel: LossExposureVulnerabilityCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureVulnerability/Create', options).toPromise();
	}

	public update(updateModel: LossExposureVulnerabilityUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureVulnerability/Update', options).toPromise();
	}
}
