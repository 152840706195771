import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ApplicationProfileGroupModel } from '../../models/application-profile-group-model.model';
import { ApplicationProfileBaseModel } from '../../models/application-profile-base-model.model';
import { ApplicationProfileModel } from '../../models/application-profile-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { ApplicationControlCreateModel } from '../../models/application-control-create-model.model';
import { ApplicationControlUpdateModel } from '../../models/application-control-update-model.model';
import { ApplicationProfileCreateModel } from '../../models/application-profile-create-model.model';

@Injectable()
export class ApplicationProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getDefaultValues(options = new HttpOptionsViewModel()): Promise<ApplicationProfileGroupModel[]> {
		return this.httpService
			.request<ApplicationProfileGroupModel[]>('get', environment.api + '/api/Configuration/ApplicationProfile/GetDefaultValues', options)
			.toPromise();
	}

	public getAll(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ApplicationProfileBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ApplicationProfileBaseModel[]>('get', environment.api + '/api/Configuration/ApplicationProfile/GetAll', options)
			.toPromise();
	}

	public getByProfileId(controlProfileId: string, options = new HttpOptionsViewModel()): Promise<ApplicationProfileModel> {
		options.params = this.httpService.getHttpParams({
			controlProfileId
		});

		return this.httpService
			.request<ApplicationProfileModel>('get', environment.api + '/api/Configuration/ApplicationProfile/GetByProfileId', options)
			.toPromise();
	}

	public getByProfileIds(controlProfileIds: string[], options = new HttpOptionsViewModel()): Promise<ApplicationProfileModel[]> {
		options.body = controlProfileIds;

		return this.httpService
			.request<ApplicationProfileModel[]>('post', environment.api + '/api/Configuration/ApplicationProfile/GetByProfileIds', options)
			.toPromise();
	}

	public create(createModel: ApplicationControlCreateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = createModel;

		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Configuration/ApplicationProfile/Create', options)
			.toPromise();
	}

	public update(updateModel: ApplicationControlUpdateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Configuration/ApplicationProfile/Update', options)
			.toPromise();
	}

	public createRange(
		legalEntityId: string,
		profiles: ApplicationProfileCreateModel[],
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = profiles;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/ApplicationProfile/CreateRange', options)
			.toPromise();
	}
}
