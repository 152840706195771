import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { TPAnalysisRiskModel } from '../../models/tpanalysis-risk-model.model';
import { Pagination } from '../../models/pagination.model';
import { TPAnalysisDefinitionModel } from '../../models/tpanalysis-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { TPAnalysisFilterModel } from '../../models/tpanalysis-filter-model.model';
import { TPAnalysisFilterOptionModel } from '../../models/tpanalysis-filter-option-model.model';

@Injectable()
export class TPAnalysisLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getTopByLoss(legalEntityId: string, count: number, options = new HttpOptionsViewModel()): Promise<TPAnalysisRiskModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			count
		});

		return this.httpService
			.request<TPAnalysisRiskModel[]>('get', environment.api + '/api/TPAnalysis/TPAnalysisLoss/GetTopByLoss', options)
			.toPromise();
	}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: TPAnalysisFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<TPAnalysisDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<TPAnalysisDefinitionModel>>('get', environment.api + '/api/TPAnalysis/TPAnalysisLoss/GetPaginated', options)
			.toPromise();
	}

	public getFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<TPAnalysisFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<TPAnalysisFilterOptionModel>('get', environment.api + '/api/TPAnalysis/TPAnalysisLoss/GetFilters', options)
			.toPromise();
	}
}
