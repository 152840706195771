import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { IntegrationSnowflakeSettingsBaseModel } from '../../models/integration-snowflake-settings-base-model.model';
import { IntegrationSnowflakeSettingsModel } from '../../models/integration-snowflake-settings-model.model';
import { IntegrationSnowflakeSettingsCreateModel } from '../../models/integration-snowflake-settings-create-model.model';
import { IntegrationSnowflakeSettingsUpdateModel } from '../../models/integration-snowflake-settings-update-model.model';

@Injectable()
export class IntegrationSnowflakeDataService {
	constructor(private httpService: RQHttpService) {}

	public isConnectionValid(
		snowflakeSettings: IntegrationSnowflakeSettingsBaseModel,
		options = new HttpOptionsViewModel()
	): Promise<boolean> {
		options.body = snowflakeSettings;

		return this.httpService
			.request<boolean>('post', environment.api + '/api/Integration/IntegrationSnowflake/IsConnectionValid', options)
			.toPromise();
	}

	public getByIntegrationId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationSnowflakeSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationSnowflakeSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationSnowflake/GetByIntegrationId',
				options
			)
			.toPromise();
	}

	public create(createModel: IntegrationSnowflakeSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/IntegrationSnowflake/Create', options).toPromise();
	}

	public update(updateModel: IntegrationSnowflakeSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('put', environment.api + '/api/Integration/IntegrationSnowflake/Update', options).toPromise();
	}

	public isAnyDefined(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Integration/IntegrationSnowflake/IsAnyDefined', options)
			.toPromise();
	}
}
