import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisBaEcGroupsModel } from '../../models/analysis-ba-ec-groups-model.model';
import { Pagination } from '../../models/pagination.model';
import { AnalysisBaEcOptionModel } from '../../models/analysis-ba-ec-option-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class AnalysisEnterpriseControlDataService {
	constructor(private httpService: RQHttpService) {}

	public getControls(
		analysisId: string,
		analysisBusinessApplicationId: string,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisBaEcGroupsModel> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			analysisBusinessApplicationId
		});

		return this.httpService
			.request<AnalysisBaEcGroupsModel>('get', environment.api + '/api/Analysis/AnalysisEnterpriseControl/GetControls', options)
			.toPromise();
	}

	public getPaginated(
		analysisId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisBaEcOptionModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisBaEcOptionModel>>(
				'get',
				environment.api + '/api/Analysis/AnalysisEnterpriseControl/GetPaginated',
				options
			)
			.toPromise();
	}

	public export(analysisId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/AnalysisEnterpriseControl/Export', options).toPromise();
	}
}
