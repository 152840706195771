import { CurrencyConfig, PercentConfig, DecimalConfig, NumberConfig } from 'rqc';
export const MAX_VALUE_ROUNDED_TO_ZERO = 1000;

export const defaultDecimalConfig = () => {
	const decimalConfig = new DecimalConfig();
	decimalConfig.trillion = 1;
	decimalConfig.billion = 1;
	decimalConfig.million = 1;
	decimalConfig.thousand = 1;
	decimalConfig.hundred = 2;
	decimalConfig.dozens = 2;
	decimalConfig.digits = 2;

	return decimalConfig;
};

export const DEFAULT_CURRENCY_CONFIG = {
	isAbbr: true,
	abbr: {
		billion: 'B',
		million: 'M',
		trillion: 'T',
		thousand: 'K',
		hundred: '',
		dozens: '',
		digits: ''
	},
	decimals: defaultDecimalConfig()
} as CurrencyConfig;

export const FINANCIAL_CURRENCY_CONFIG = {
	isAbbr: false,
	decimals: 2,
	rounding: undefined
} as CurrencyConfig;

export const DEFAULT_PERCENT_CONFIG = {
	decimals: 2
} as PercentConfig;

export const DEFAULT_NUMBER_CONFIG = {
	decimals: 2
} as NumberConfig;
