import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { LegalEntityUserModel } from '../../models/legal-entity-user-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { UserFilterModel } from '../../models/user-filter-model.model';
import { UserModel } from '../../models/user-model.model';
import { AssignUserFilterOptionModel } from '../../models/assign-user-filter-option-model.model';
import { LegalEntityAssignModel } from '../../models/legal-entity-assign-model.model';

@Injectable()
export class EnterpriseLegalEntityUserDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: UserFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LegalEntityUserModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<LegalEntityUserModel>>('get', environment.api + '/api/Enterprise/EnterpriseLegalEntityUser/GetPaginated', options)
			.toPromise();
	}

	public getPaginatedByLegalEntity(
		legalEntityId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<UserModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination
		});

		return this.httpService
			.request<Pagination<UserModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseLegalEntityUser/GetPaginatedByLegalEntity',
				options
			)
			.toPromise();
	}

	public getFiltersByLegalEntity(options = new HttpOptionsViewModel()): Promise<AssignUserFilterOptionModel> {
		return this.httpService
			.request<AssignUserFilterOptionModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseLegalEntityUser/GetFiltersByLegalEntity',
				options
			)
			.toPromise();
	}

	public update(assignation: LegalEntityAssignModel, filter: UserFilterModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseLegalEntityUser/Update', options).toPromise();
	}
}
