import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { EndpointScoreWeightingViewModel } from '../../models/endpoint-score-weighting-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';

@Injectable()
export class EndpointScoreWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<EndpointScoreWeightingViewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<EndpointScoreWeightingViewModel>('get', environment.api + '/api/Settings/EndpointScoreWeighting/GetByLegalEntityId', options)
			.toPromise();
	}

	public revertToDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Settings/EndpointScoreWeighting/RevertToDefaults', options)
			.toPromise();
	}

	public update(
		endpointScoreWeightingModel: EndpointScoreWeightingViewModel,
		options = new HttpOptionsViewModel()
	): Promise<IActionResult> {
		options.body = endpointScoreWeightingModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Settings/EndpointScoreWeighting/Update', options)
			.toPromise();
	}
}
