import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { CveScoreWeightingModel } from '../../models/cve-score-weighting-model.model';

@Injectable()
export class CveScoreWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<CveScoreWeightingModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<CveScoreWeightingModel[]>('get', environment.api + '/api/Settings/CveScoreWeighting/GetByLegalEntity', options)
			.toPromise();
	}

	public update(scoreWeightings: CveScoreWeightingModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = scoreWeightings;

		return this.httpService.request<void>('put', environment.api + '/api/Settings/CveScoreWeighting/Update', options).toPromise();
	}
}
