import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ApplicationCharacteristicModel } from '../../models/application-characteristic-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ApplicationCharacteristicFilterModel } from '../../models/application-characteristic-filter-model.model';
import { ApplicationCharacteristicFilterOptionModel } from '../../models/application-characteristic-filter-option-model.model';
import { ApplicationCharacteristicCreateModel } from '../../models/application-characteristic-create-model.model';
import { ApplicationCharacteristicUpdateModel } from '../../models/application-characteristic-update-model.model';

@Injectable()
export class ApplicationCharacteristicDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		pagination: PaginationFilter,
		filter: ApplicationCharacteristicFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ApplicationCharacteristicModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ApplicationCharacteristicModel>>(
				'get',
				environment.api + '/api/Enterprise/ApplicationCharacteristic/GetPaginated',
				options
			)
			.toPromise();
	}

	public getAll(options = new HttpOptionsViewModel()): Promise<ApplicationCharacteristicModel[]> {
		return this.httpService
			.request<ApplicationCharacteristicModel[]>('get', environment.api + '/api/Enterprise/ApplicationCharacteristic/GetAll', options)
			.toPromise();
	}

	public getFilterOptions(options = new HttpOptionsViewModel()): Promise<ApplicationCharacteristicFilterOptionModel> {
		return this.httpService
			.request<ApplicationCharacteristicFilterOptionModel>(
				'get',
				environment.api + '/api/Enterprise/ApplicationCharacteristic/GetFilterOptions',
				options
			)
			.toPromise();
	}

	public getByName(name: string, options = new HttpOptionsViewModel()): Promise<ApplicationCharacteristicModel> {
		options.params = this.httpService.getHttpParams({
			name
		});

		return this.httpService
			.request<ApplicationCharacteristicModel>('get', environment.api + '/api/Enterprise/ApplicationCharacteristic/GetByName', options)
			.toPromise();
	}

	public getById(applicationCharacteristicId: string, options = new HttpOptionsViewModel()): Promise<ApplicationCharacteristicModel> {
		options.params = this.httpService.getHttpParams({
			applicationCharacteristicId
		});

		return this.httpService
			.request<ApplicationCharacteristicModel>('get', environment.api + '/api/Enterprise/ApplicationCharacteristic/GetById', options)
			.toPromise();
	}

	public create(createModel: ApplicationCharacteristicCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = createModel;

		return this.httpService
			.request<string>('post', environment.api + '/api/Enterprise/ApplicationCharacteristic/Create', options)
			.toPromise();
	}

	public update(updateModel: ApplicationCharacteristicUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Enterprise/ApplicationCharacteristic/Update', options)
			.toPromise();
	}
}
