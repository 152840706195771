import { Injectable } from '@angular/core';
import { ThemeKeys, ThemeService } from 'rqc';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class BootstrapService {
	constructor(private translationService: TranslationService, private themeService: ThemeService) {}

	public load() {
		return new Promise(async resolve => {
			await Promise.all([this.translationService.load()])
				.then(() => {
					this.themeService.setTheme(ThemeKeys.Default);

					resolve(null);
				})
				// eslint-disable-next-line no-console
				.catch(error => console.error(error));
		});
	}
}
