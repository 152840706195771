import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { FeatureFlag } from '../../models/feature-flag.model';
import { EFeatureFlag } from '../../enums/efeature-flag.enum';
import { ActionResult } from '../../models/action-result.model';

@Injectable()
export class FeatureFlagDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<FeatureFlag[]> {
		return this.httpService.request<FeatureFlag[]>('get', environment.api + '/api/Infrastructure/FeatureFlag/GetAll', options).toPromise();
	}

	public getByName(featureFlagName: EFeatureFlag, options = new HttpOptionsViewModel()): Promise<FeatureFlag> {
		options.params = this.httpService.getHttpParams({
			featureFlagName
		});

		return this.httpService.request<FeatureFlag>('get', environment.api + '/api/Infrastructure/FeatureFlag/GetByName', options).toPromise();
	}

	public update(featureFlag: FeatureFlag, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = featureFlag;

		return this.httpService.request<ActionResult>('post', environment.api + '/api/Infrastructure/FeatureFlag/Update', options).toPromise();
	}
}
