import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class HealthDataService {
	constructor(private httpService: RQHttpService) {}

	public isReady(options = new HttpOptionsViewModel()): Promise<boolean> {
		return this.httpService.request<boolean>('get', environment.api + '/api/Infrastructure/Health/IsReady', options).toPromise();
	}
}
