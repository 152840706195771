import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ScenarioDetailsRateOfIncidentModel } from '../../models/scenario-details-rate-of-incident-model.model';
import { ScenarioDetailsInputModel } from '../../models/scenario-details-input-model.model';

@Injectable()
export class ScenarioDetailsRateOfIncidentDataService {
	constructor(private httpService: RQHttpService) {}

	public get(
		legalEntityId: string,
		input: ScenarioDetailsInputModel,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioDetailsRateOfIncidentModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = input;

		return this.httpService
			.request<ScenarioDetailsRateOfIncidentModel[]>('post', environment.api + '/api/Scenario/ScenarioDetailsRateOfIncident/Get', options)
			.toPromise();
	}
}
