import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ScenarioSchedulerSettingsModel } from '../../models/scenario-scheduler-settings-model.model';
import { ScenarioSchedulerCreateSettingsModel } from '../../models/scenario-scheduler-create-settings-model.model';
import { ScenarioSchedulerUpdateSettingsModel } from '../../models/scenario-scheduler-update-settings-model.model';

@Injectable()
export class ScenarioSchedulerDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioSchedulerSettingsModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioSchedulerSettingsModel>('get', environment.api + '/api/Scenario/ScenarioScheduler/GetById', options)
			.toPromise();
	}

	public create(createModel: ScenarioSchedulerCreateSettingsModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/ScenarioScheduler/Create', options).toPromise();
	}

	public update(updateModel: ScenarioSchedulerUpdateSettingsModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/ScenarioScheduler/Update', options).toPromise();
	}
}
