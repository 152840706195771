import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { LossExposureDefinitionModel } from '../../models/loss-exposure-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LossExposureFilterModel } from '../../models/loss-exposure-filter-model.model';
import { LossExposureModel } from '../../models/loss-exposure-model.model';
import { LossExposureCreateModel } from '../../models/loss-exposure-create-model.model';
import { LossExposureUpdateModel } from '../../models/loss-exposure-update-model.model';
import { ActionResult } from '../../models/action-result.model';

@Injectable()
export class LossExposureDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: LossExposureFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LossExposureDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<LossExposureDefinitionModel>>('get', environment.api + '/api/Configuration/LossExposure/GetPaginated', options)
			.toPromise();
	}

	public getByFilters(
		legalEntityId: string,
		filter: LossExposureFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<LossExposureDefinitionModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter
		});

		return this.httpService
			.request<LossExposureDefinitionModel[]>('get', environment.api + '/api/Configuration/LossExposure/GetByFilters', options)
			.toPromise();
	}

	public getByLegalEntityAndFilter(
		legalEntityId: string,
		filter: LossExposureFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<LossExposureModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter
		});

		return this.httpService
			.request<LossExposureModel[]>('get', environment.api + '/api/Configuration/LossExposure/GetByLegalEntityAndFilter', options)
			.toPromise();
	}

	public create(
		legalEntityId: string,
		profileModel: LossExposureCreateModel,
		options = new HttpOptionsViewModel()
	): Promise<LossExposureModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = profileModel;

		return this.httpService
			.request<LossExposureModel>('post', environment.api + '/api/Configuration/LossExposure/Create', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<LossExposureModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService
			.request<LossExposureModel>('get', environment.api + '/api/Configuration/LossExposure/GetByName', options)
			.toPromise();
	}

	public getById(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureModel>('get', environment.api + '/api/Configuration/LossExposure/GetById', options)
			.toPromise();
	}

	public update(legalEntityId: string, profileModel: LossExposureUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = profileModel;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/LossExposure/Update', options).toPromise();
	}

	public delete(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<ActionResult>('delete', environment.api + '/api/Configuration/LossExposure/Delete', options)
			.toPromise();
	}
}
