import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { IntegrationSureCloudSettingsCreateModel } from '../../models/integration-sure-cloud-settings-create-model.model';
import { IntegrationSureCloudSettingsModel } from '../../models/integration-sure-cloud-settings-model.model';

@Injectable()
export class IntegrationSureCloudSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public create(model: IntegrationSureCloudSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationSureCloudSettings/Create', options)
			.toPromise();
	}

	public update(model: IntegrationSureCloudSettingsModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('put', environment.api + '/api/Integration/IntegrationSureCloudSettings/Update', options)
			.toPromise();
	}

	public getByIntegrationSettingId(
		integrationId: string,
		options = new HttpOptionsViewModel()
	): Promise<IntegrationSureCloudSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationSureCloudSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationSureCloudSettings/GetByIntegrationSettingId',
				options
			)
			.toPromise();
	}
}
