import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { BaLossExposureDefinitionModel } from '../../models/ba-loss-exposure-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { BaLossExposureFilterModel } from '../../models/ba-loss-exposure-filter-model.model';
import { BaLossExposureFilterOptionModel } from '../../models/ba-loss-exposure-filter-option-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { BaLossExposureUpdateModel } from '../../models/ba-loss-exposure-update-model.model';

@Injectable()
export class BaLossExposureDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		businessApplicationId: string,
		pagination: PaginationFilter,
		filter: BaLossExposureFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BaLossExposureDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BaLossExposureDefinitionModel>>(
				'get',
				environment.api + '/api/Configuration/BaLossExposure/GetPaginated',
				options
			)
			.toPromise();
	}

	public getAllSelected(
		legalEntityId: string,
		businessApplicationId: string,
		options = new HttpOptionsViewModel()
	): Promise<BaLossExposureDefinitionModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId
		});

		return this.httpService
			.request<BaLossExposureDefinitionModel[]>('get', environment.api + '/api/Configuration/BaLossExposure/GetAllSelected', options)
			.toPromise();
	}

	public getFilters(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<BaLossExposureFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<BaLossExposureFilterOptionModel>('get', environment.api + '/api/Configuration/BaLossExposure/GetFilters', options)
			.toPromise();
	}

	public update(
		assignation: BaLossExposureUpdateModel,
		filter: BaLossExposureFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = assignation;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Configuration/BaLossExposure/Update', options)
			.toPromise();
	}

	public updateRange(assignations: BaLossExposureUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignations;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaLossExposure/UpdateRange', options).toPromise();
	}
}
