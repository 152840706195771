import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { RecommendationCostUpdateViewModel } from '../../models/recommendation-cost-update-view-model.model';
import { RecommendationCostCreateViewModel } from '../../models/recommendation-cost-create-view-model.model';

@Injectable()
export class EnterpriseRecommendationCostDataService {
	constructor(private httpService: RQHttpService) {}

	public update(implementation: RecommendationCostUpdateViewModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = implementation;

		return this.httpService
			.request<void>('put', environment.api + '/api/Enterprise/EnterpriseRecommendationCost/Update', options)
			.toPromise();
	}

	public create(implementation: RecommendationCostCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = implementation;

		return this.httpService
			.request<string>('post', environment.api + '/api/Enterprise/EnterpriseRecommendationCost/Create', options)
			.toPromise();
	}

	public delete(implementationId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			implementationId
		});

		return this.httpService
			.request<void>('delete', environment.api + '/api/Enterprise/EnterpriseRecommendationCost/Delete', options)
			.toPromise();
	}
}
