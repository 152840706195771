import { Injectable } from '@angular/core';
import { Customizer } from 'rqc';
import { environment } from '../../../../environments/environment';
import { TranslationService } from '../services/translation.service';
import { DEFAULT_CURRENCY_CONFIG, DEFAULT_NUMBER_CONFIG, DEFAULT_PERCENT_CONFIG } from './number-formatter.config';

@Injectable()
export class RQConfig extends Customizer {
	public culture = 'en';

	public date = { format: 'MM/DD/YYYY' };

	public dateTime = { format: 'MMMM D, YYYY LT' };

	public isProduction = environment.production;

	constructor(private translateService: TranslationService) {
		super();

		this.configNumberFormatters();
	}

	public translate = (key: string, param?: { [key: string]: string | number }) => this.translateService.get(key, param);

	private configNumberFormatters() {
		this.formatter.number = Object.assign(this.formatter.number, DEFAULT_NUMBER_CONFIG);
		this.formatter.percent = Object.assign(this.formatter.percent, DEFAULT_PERCENT_CONFIG);
		this.formatter.currency = Object.assign(this.formatter.currency, DEFAULT_CURRENCY_CONFIG);
	}
}
