import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthorizationDataService {
	constructor(private httpService: RQHttpService) {}

	public hasEnterpriseAccessRights(options = new HttpOptionsViewModel()): Promise<boolean> {
		return this.httpService
			.request<boolean>('get', environment.api + '/api/Identity/Authorization/HasEnterpriseAccessRights', options)
			.toPromise();
	}

	public hasProAccessRights(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Identity/Authorization/HasProAccessRights', options)
			.toPromise();
	}

	public hasEnterpriseReadOnlyAccessRights(options = new HttpOptionsViewModel()): Promise<boolean> {
		return this.httpService
			.request<boolean>('get', environment.api + '/api/Identity/Authorization/HasEnterpriseReadOnlyAccessRights', options)
			.toPromise();
	}

	public hasRole(role: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			role
		});

		return this.httpService.request<boolean>('get', environment.api + '/api/Identity/Authorization/HasRole', options).toPromise();
	}
}
