import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { LegalEntityModel } from '../../models/legal-entity-model.model';
import { LegalEntityStateModel } from '../../models/legal-entity-state-model.model';
import { LegalEntityUpdateModel } from '../../models/legal-entity-update-model.model';

@Injectable()
export class LegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LegalEntityModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LegalEntityModel>('get', environment.api + '/api/Configuration/LegalEntity/GetById', options)
			.toPromise();
	}

	public getByName(name: string, options = new HttpOptionsViewModel()): Promise<LegalEntityModel> {
		options.params = this.httpService.getHttpParams({
			name
		});

		return this.httpService
			.request<LegalEntityModel>('get', environment.api + '/api/Configuration/LegalEntity/GetByName', options)
			.toPromise();
	}

	public getState(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LegalEntityStateModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LegalEntityStateModel>('get', environment.api + '/api/Configuration/LegalEntity/GetState', options)
			.toPromise();
	}

	public update(updatedLegalEntity: LegalEntityUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = updatedLegalEntity;

		return this.httpService.request<string>('put', environment.api + '/api/Configuration/LegalEntity/Update', options).toPromise();
	}
}
