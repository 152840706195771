import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ActionResult } from '../../models/action-result.model';

@Injectable()
export class TranslationDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<{ [key: string]: string }> {
		return this.httpService
			.request<{ [key: string]: string }>('get', environment.api + '/api/Infrastructure/Translation/Get', options)
			.toPromise();
	}

	public getDifferencesByCuture(cultureName: string, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.params = this.httpService.getHttpParams({
			cultureName
		});

		return this.httpService
			.request<ActionResult>('get', environment.api + '/api/Infrastructure/Translation/GetDifferencesByCuture', options)
			.toPromise();
	}
}
