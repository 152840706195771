import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ApplicationTypeModel } from '../../models/application-type-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ApplicationTypeFilterModel } from '../../models/application-type-filter-model.model';
import { ApplicationTypeFilterOptionModel } from '../../models/application-type-filter-option-model.model';
import { ApplicationTypeCreateModel } from '../../models/application-type-create-model.model';
import { ApplicationTypeUpdateModel } from '../../models/application-type-update-model.model';

@Injectable()
export class ApplicationTypeDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		pagination: PaginationFilter,
		filter: ApplicationTypeFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ApplicationTypeModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ApplicationTypeModel>>('get', environment.api + '/api/Enterprise/ApplicationType/GetPaginated', options)
			.toPromise();
	}

	public getAll(options = new HttpOptionsViewModel()): Promise<ApplicationTypeModel[]> {
		return this.httpService
			.request<ApplicationTypeModel[]>('get', environment.api + '/api/Enterprise/ApplicationType/GetAll', options)
			.toPromise();
	}

	public getFilterOptions(options = new HttpOptionsViewModel()): Promise<ApplicationTypeFilterOptionModel> {
		return this.httpService
			.request<ApplicationTypeFilterOptionModel>('get', environment.api + '/api/Enterprise/ApplicationType/GetFilterOptions', options)
			.toPromise();
	}

	public getByName(name: string, options = new HttpOptionsViewModel()): Promise<ApplicationTypeModel> {
		options.params = this.httpService.getHttpParams({
			name
		});

		return this.httpService
			.request<ApplicationTypeModel>('get', environment.api + '/api/Enterprise/ApplicationType/GetByName', options)
			.toPromise();
	}

	public getById(applicationTypeId: string, options = new HttpOptionsViewModel()): Promise<ApplicationTypeModel> {
		options.params = this.httpService.getHttpParams({
			applicationTypeId
		});

		return this.httpService
			.request<ApplicationTypeModel>('get', environment.api + '/api/Enterprise/ApplicationType/GetById', options)
			.toPromise();
	}

	public create(createModel: ApplicationTypeCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = createModel;

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/ApplicationType/Create', options).toPromise();
	}

	public update(updateModel: ApplicationTypeUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/ApplicationType/Update', options).toPromise();
	}
}
