import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { BaRevenueModel } from '../../models/ba-revenue-model.model';

@Injectable()
export class BaRevenueDataService {
	constructor(private httpService: RQHttpService) {}

	public getByIds(businessApplicationIds: string[], options = new HttpOptionsViewModel()): Promise<BaRevenueModel[]> {
		options.body = businessApplicationIds;

		return this.httpService
			.request<BaRevenueModel[]>('post', environment.api + '/api/Configuration/BaRevenue/GetByIds', options)
			.toPromise();
	}
}
