import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Customizer, DatePipe, ThemeKeys, ThemeService } from 'rqc';
import { AppComponent } from './app.component';
import { RQConfig } from './_common/core/configs/config';
import { AppInjector } from './_common/core/utilities/app-injector';
import { BootstrapService } from './_common/core/services/bootstrap.service';
import { RQRouteReuseStrategyService } from './_common/core/utilities/route-reuse-strategy';
import { NgxPendoModule } from 'ngx-pendo';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { FeatureFlagService } from './_common/share/services/feature-flag.service';
import { CoreModule } from './_common/core/core.module';
import { CommonModule } from '@angular/common';

export function appInit(bootstrapService: BootstrapService) {
	return () => bootstrapService.load();
}

export function featuresFlagInit(featureFlagService: FeatureFlagService) {
	return () => featureFlagService.load();
}

function setupTheme(themeService: ThemeService) {
	return () => themeService.setTheme(ThemeKeys.Default);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CoreModule,
		NgxPendoModule.forRoot({
			pendoApiKey: environment.analyticsId,
			pendoIdFormatter: value => value.toString().toLowerCase()
		})
	],
	providers: [
		{
			provide: Customizer,
			useClass: RQConfig
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInit,
			deps: [BootstrapService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: featuresFlagInit,
			deps: [FeatureFlagService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: setupTheme,
			deps: [ThemeService],
			multi: true
		},
		{
			provide: RouteReuseStrategy,
			useClass: RQRouteReuseStrategyService
		},
		DatePipe
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(injector: Injector) {
		AppInjector.injector = injector;
	}
}
