import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ImpactVectorViewModel } from '../../models/impact-vector-view-model.model';

@Injectable()
export class AnalysisImpactVectorDataService {
	constructor(private httpService: RQHttpService) {}

	public createRange(lossImpacts: ImpactVectorViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = lossImpacts;

		return this.httpService.request<void>('post', environment.api + '/api/Analysis/AnalysisImpactVector/CreateRange', options).toPromise();
	}
}
