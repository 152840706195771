import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { BaApplicationProfileModel } from '../../models/ba-application-profile-model.model';
import { BaApplicationProfileUpdateModel } from '../../models/ba-application-profile-update-model.model';

@Injectable()
export class BaApplicationProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getByApplicationId(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<BaApplicationProfileModel> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<BaApplicationProfileModel>('get', environment.api + '/api/Configuration/BaApplicationProfile/GetByApplicationId', options)
			.toPromise();
	}

	public update(assignation: BaApplicationProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaApplicationProfile/Update', options).toPromise();
	}

	public updateRange(assignations: BaApplicationProfileUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignations;

		return this.httpService
			.request<void>('put', environment.api + '/api/Configuration/BaApplicationProfile/UpdateRange', options)
			.toPromise();
	}
}
