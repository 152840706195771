import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { LossExposureTEFModel } from '../../models/loss-exposure-tefmodel.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LossExposureTEFFilterModel } from '../../models/loss-exposure-teffilter-model.model';
import { LossExposureTEFFilterOptionModel } from '../../models/loss-exposure-teffilter-option-model.model';
import { LossExposureTEFCreateModel } from '../../models/loss-exposure-tefcreate-model.model';
import { LossExposureTEFUpdateModel } from '../../models/loss-exposure-tefupdate-model.model';

@Injectable()
export class LossExposureTEFDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		lossExposureId: string,
		pagination: PaginationFilter,
		filter: LossExposureTEFFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LossExposureTEFModel>> {
		options.params = this.httpService.getHttpParams({
			lossExposureId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<LossExposureTEFModel>>('get', environment.api + '/api/TPConfig/LossExposureTEF/GetPaginated', options)
			.toPromise();
	}

	public count(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService.request<number>('get', environment.api + '/api/TPConfig/LossExposureTEF/Count', options).toPromise();
	}

	public getFilterOptions(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureTEFFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureTEFFilterOptionModel>('get', environment.api + '/api/TPConfig/LossExposureTEF/GetFilterOptions', options)
			.toPromise();
	}

	public getById(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureTEFModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureTEFModel>('get', environment.api + '/api/TPConfig/LossExposureTEF/GetById', options)
			.toPromise();
	}

	public create(createModel: LossExposureTEFCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureTEF/Create', options).toPromise();
	}

	public update(updateModel: LossExposureTEFUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureTEF/Update', options).toPromise();
	}

	public delete(id: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			id
		});

		return this.httpService.request<void>('delete', environment.api + '/api/TPConfig/LossExposureTEF/Delete', options).toPromise();
	}
}
