import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { FairAnalysisLossModel } from '../../models/fair-analysis-loss-model.model';
import { FairType } from '../../enums/fair-type.enum';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { FairLossFilterModel } from '../../models/fair-loss-filter-model.model';
import { FairLossFilterOptionModel } from '../../models/fair-loss-filter-option-model.model';

@Injectable()
export class FairAnalysisLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		scenarioId: string,
		type: FairType,
		pagination: PaginationFilter,
		filter: FairLossFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<FairAnalysisLossModel>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<FairAnalysisLossModel>>('get', environment.api + '/api/Fair/FairAnalysisLoss/GetPaginated', options)
			.toPromise();
	}

	public getFilterOptions(scenarioId: string, type: FairType, options = new HttpOptionsViewModel()): Promise<FairLossFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type
		});

		return this.httpService
			.request<FairLossFilterOptionModel>('get', environment.api + '/api/Fair/FairAnalysisLoss/GetFilterOptions', options)
			.toPromise();
	}

	public count(scenarioId: string, type: FairType, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type
		});

		return this.httpService.request<number>('get', environment.api + '/api/Fair/FairAnalysisLoss/Count', options).toPromise();
	}
}
