import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ErrorHandlingService } from './services/error-handling.service';
import { ServerServicesModule } from '../server/services/server-services.module';
import { HttpHeaderInterceptorService } from './interceptors/http-header-interceptor.service';
import { AuditInterceptorService } from './interceptors/audit-interceptor.service';
import { NotificationComponent } from './components/notification/notification.component';
import { RQCLibModule } from 'rqc';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.page';
import { NavigationInterceptor } from './interceptors/navigation.interceptor';

@NgModule({
	declarations: [NotificationComponent, PageNotFoundComponent],
	exports: [NotificationComponent],
	imports: [CommonModule, ServerServicesModule, HttpClientModule, RQCLibModule.forChild(), RouterModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpHeaderInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuditInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: NavigationInterceptor,
			multi: true
		},
		{ provide: ErrorHandler, useClass: ErrorHandlingService }
	]
})
export class CoreModule {}
