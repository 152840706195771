import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoggerType, LogType } from '../enums/log-type.enum';
import { HttpStatusCode } from '../enums/http-status-code.enum';
import { LogViewModel } from '../models/log.model';
import { HttpParserService } from './http-parser.service';
import { LoggerService } from './logger.service';
import { TranslationService } from './translation.service';
import { RQ_ROUTES } from '../configs/routes.config';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlingService implements ErrorHandler {
	constructor(
		private httpResponseService: HttpParserService,
		private loggerService: LoggerService,
		private translationService: TranslationService,
		private router: Router
	) {}

	public handleError(error: Error) {
		const httpError = this.unwrapHttpError(error as HttpErrorResponse);

		if (httpError !== null) {
			this.handleHttpErrorResponse(httpError);
		} else {
			this.handleGeneralError(error);
		}
	}

	private unwrapHttpError(error: HttpErrorResponse | { rejection: HttpErrorResponse }): HttpErrorResponse | null {
		let result = null;

		if (error instanceof HttpErrorResponse) {
			result = error;
		} else if (error.rejection !== null && error.rejection instanceof HttpErrorResponse) {
			result = error.rejection;
		}

		return result;
	}

	private handleHttpErrorResponse(error: HttpErrorResponse) {
		const errorMessage = this.httpResponseService.getError(error);

		this.handleHttpError(error.status, errorMessage);
	}

	private handleHttpError(status: HttpStatusCode, httpError: string) {
		const devLog = new LogViewModel(httpError, LogType.Error);
		const productionLog = this.createProductionLog(status);

		this.logError(devLog, productionLog);
	}

	private createProductionLog(status: HttpStatusCode) {
		let userMessage = '';

		switch (status) {
			case HttpStatusCode.SERVER_OFFLINE:
				userMessage = this.translationService.get('global_error_serverOffline');
				break;
			case HttpStatusCode.UNAUTHORIZED:
				userMessage = this.translationService.get('global_error_unauthorized');
				this.router.navigateByUrl(RQ_ROUTES.security.login.url());
				break;
			case HttpStatusCode.FORBIDDEN:
				userMessage = this.translationService.get('global_error_unauthorized');
				break;
			default:
				userMessage = this.translationService.get('global_error_message');
				break;
		}

		return new LogViewModel(userMessage, LogType.Error);
	}

	private handleGeneralError(error: Error) {
		const devLog = new LogViewModel(error.message, LogType.Error, error.stack);
		const productionLog = new LogViewModel(this.translationService.get('global_error_message'), LogType.Error);

		this.logError(devLog, productionLog);
	}

	private logError(devLog: LogViewModel, productionLog: LogViewModel) {
		if (!environment.production) {
			this.loggerService.log(devLog, [LoggerType.Console, LoggerType.Frontend, LoggerType.Api]);
		} else {
			this.loggerService.log(devLog, [LoggerType.Api, LoggerType.Console]);
			this.loggerService.log(productionLog, [LoggerType.Frontend]);
		}
	}
}
