import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { InstanceStateModel } from '../../models/instance-state-model.model';
import { InstanceModel } from '../../models/instance-model.model';

@Injectable()
export class InstanceDataService {
	constructor(private httpService: RQHttpService) {}

	public getState(options = new HttpOptionsViewModel()): Promise<InstanceStateModel> {
		return this.httpService.request<InstanceStateModel>('get', environment.api + '/api/Analysis/Instance/GetState', options).toPromise();
	}

	public get(options = new HttpOptionsViewModel()): Promise<InstanceModel> {
		return this.httpService.request<InstanceModel>('get', environment.api + '/api/Analysis/Instance/Get', options).toPromise();
	}
}
