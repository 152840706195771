import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ScenarioDefinitionModel } from '../../models/scenario-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ScenarioFilterModel } from '../../models/scenario-filter-model.model';
import { AggregatedFairModel } from '../../models/aggregated-fair-model.model';

@Injectable()
export class AggregatedFairDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		scenarioId: string,
		pagination: PaginationFilter,
		filter: ScenarioFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ScenarioDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ScenarioDefinitionModel>>('get', environment.api + '/api/Scenario/AggregatedFair/GetPaginated', options)
			.toPromise();
	}

	public update(updateModel: AggregatedFairModel, filter: ScenarioFilterModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = updateModel;

		return this.httpService.request<void>('put', environment.api + '/api/Scenario/AggregatedFair/Update', options).toPromise();
	}

	public delete(scenarioId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Scenario/AggregatedFair/Delete', options).toPromise();
	}
}
