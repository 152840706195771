import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { IndustryModel } from '../../models/industry-model.model';

@Injectable()
export class IndustryDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<IndustryModel[]> {
		return this.httpService.request<IndustryModel[]>('get', environment.api + '/api/Configuration/Industry/GetAll', options).toPromise();
	}
}
