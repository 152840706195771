import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InstanceDataService } from '../../server/services/analysis/instance-data.service';
import { ApplicationState } from '../../server/enums/application-state.enum';
import { IdentityService } from '../../share/services/identity.service';
import { IDPDataService } from '../../share/services/idp-data.service';
import { LegalEntityUserDataService } from '../../server/services/configuration/legal-entity-user-data.service';
import { AccountDataService } from '../../server/services/identity/account-data.service';
import { AuthorizationDataService } from '../../server/services/identity/authorization-data.service';
import { ApplicationDataService } from '../../server/services/infrastructure/application-data.service';
import { RQ_ROUTES } from '../configs/routes.config';
import { LegalEntityType } from '../../server/enums/legal-entity-type.enum';
import { LegalEntityModel } from '../../server/models/legal-entity-model.model';

@Injectable({
	providedIn: 'root'
})
export class HomeService {
	constructor(
		private router: Router,
		private legalEntityUserDataService: LegalEntityUserDataService,
		private instanceDataService: InstanceDataService,
		private authorizationDataService: AuthorizationDataService,
		private accountDataService: AccountDataService,
		private applicationDataService: ApplicationDataService,
		private identityService: IdentityService,
		private idpDataService: IDPDataService
	) {}

	public async navigateHome(legalEntityId?: string) {
		const isAuthenticated = await this.accountDataService.isAuthenticated();
		isAuthenticated ? await this.navigateToApp(legalEntityId) : await this.navigateToLogin();
	}

	private async navigateToApp(legalEntityId?: string) {
		const hasEnterpriseDataAccess = await this.authorizationDataService.hasEnterpriseAccessRights();

		let url;

		const application = await this.applicationDataService.get();

		if (application.state === ApplicationState.Ready) {
			url = await this.createApplicationUrl(hasEnterpriseDataAccess, legalEntityId);
		} else {
			url = RQ_ROUTES.infrastructure.migration.url;
		}

		await this.router.navigateByUrl(url);
	}

	private async createApplicationUrl(hasEnterpriseDataAccess: boolean, legalEntityId?: string) {
		let url;

		if (hasEnterpriseDataAccess) {
			const state = await this.instanceDataService.getState();

			url = state.isAtLeaseOneRiskAnalysisConfigured
				? RQ_ROUTES.enterprise.portfolioAnalysis.url()
				: RQ_ROUTES.enterprise.configuration.overview.url();
		} else {
			url = await this.createProOrFidelityOneUrl(legalEntityId);
		}

		return url;
	}

	private async createProOrFidelityOneUrl(legalEntityId: string | undefined) {
		const isFairOnlyUser = this.identityService.isFairOnly;
		const activeLegalEntities = await this.legalEntityUserDataService.getByUser();
		let legalEntity: LegalEntityModel;

		if (legalEntityId) {
			legalEntity = activeLegalEntities.find(x => x.id === legalEntityId) as LegalEntityModel;
		} else if (activeLegalEntities?.length > 0) {
			legalEntity = activeLegalEntities[0];
		} else {
			return RQ_ROUTES.infrastructure.message.url;
		}

		if (legalEntity?.type === LegalEntityType.FinancialImpacts) {
			return RQ_ROUTES.fidelityOne.dashboard.url(legalEntity.id);
		} else if (legalEntity?.type === LegalEntityType.Mitre) {
			return RQ_ROUTES.pro.mitreDashboard.url(legalEntity.id);
		} else {
			return isFairOnlyUser ? RQ_ROUTES.pro.scenarios.url(legalEntity.id) : RQ_ROUTES.pro.riskAnalysis.url(legalEntity.id);
		}
	}

	private async navigateToLogin() {
		const providers = await this.idpDataService.getExternalLogins();

		const isProviderLoginUrl = providers?.externalProviders?.length > 0 && providers.externalProviders[0].providerLoginFirst;

		if (isProviderLoginUrl) {
			location.href = providers.loginExternalUrl + providers.externalProviders[0].authenticationScheme;
		} else {
			await this.router.navigateByUrl(RQ_ROUTES.security.login.url());
		}
	}
}
