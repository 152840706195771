import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisBaEnterpriseControlModel } from '../../models/analysis-ba-enterprise-control-model.model';
import { ScenarioDetailsMcpInputViewModel } from '../../models/scenario-details-mcp-input-view-model.model';
import { ScenarioAnalysisMcpViewModel } from '../../models/scenario-analysis-mcp-view-model.model';
import { ScenarioDetailsSummaryViewModel } from '../../models/scenario-details-summary-view-model.model';
import { ScenarioDetailsLossBreakdownModel } from '../../models/scenario-details-loss-breakdown-model.model';
import { ScenarioMcpFilterOutputModel } from '../../models/scenario-mcp-filter-output-model.model';

@Injectable()
export class ScenarioAnalysisMcpDataService {
	constructor(private httpService: RQHttpService) {}

	public getDetailsControls(
		input: ScenarioDetailsMcpInputViewModel,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisBaEnterpriseControlModel[]> {
		options.body = input;

		return this.httpService
			.request<AnalysisBaEnterpriseControlModel[]>(
				'post',
				environment.api + '/api/Scenario/ScenarioAnalysisMcp/GetDetailsControls',
				options
			)
			.toPromise();
	}

	public getProfiles(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioAnalysisMcpViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioAnalysisMcpViewModel>('get', environment.api + '/api/Scenario/ScenarioAnalysisMcp/GetProfiles', options)
			.toPromise();
	}

	public getSummary(
		input: ScenarioDetailsMcpInputViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioDetailsSummaryViewModel[]> {
		options.body = input;

		return this.httpService
			.request<ScenarioDetailsSummaryViewModel[]>('post', environment.api + '/api/Scenario/ScenarioAnalysisMcp/GetSummary', options)
			.toPromise();
	}

	public getLosses(
		input: ScenarioDetailsMcpInputViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioDetailsLossBreakdownModel[]> {
		options.body = input;

		return this.httpService
			.request<ScenarioDetailsLossBreakdownModel[]>('post', environment.api + '/api/Scenario/ScenarioAnalysisMcp/GetLosses', options)
			.toPromise();
	}

	public getByFilter(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMcpFilterOutputModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMcpFilterOutputModel>('get', environment.api + '/api/Scenario/ScenarioAnalysisMcp/GetByFilter', options)
			.toPromise();
	}
}
