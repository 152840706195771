import { Injectable } from '@angular/core';
import { TranslationDataService } from '../../server/services/infrastructure/translation-data.service';

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	public resource!: { [key: string]: string };

	constructor(private translateDataService: TranslationDataService) {}

	public async load() {
		this.resource = await this.translateDataService.get();
	}

	public get(key: string, param?: { [key: string]: string | number }) {
		let value = this.resource[key];

		if (param) {
			Object.keys(param).forEach(paramKey => {
				value = this.replace(value, { key: `{{${paramKey}}}`, value: param[paramKey]?.toString() });
			});
		}

		return value ? value : key;
	}

	private replace(value: string, param: { key: string; value: string }) {
		value = value ? value.replace(param.key, param.value) : '';

		if (value.includes(param.key)) {
			value = this.replace(value, param);
		}

		return value;
	}
}
