import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { OpenIdConfigurationModel } from '../../models/open-id-configuration-model.model';
import { SamlConfigurationModel } from '../../models/saml-configuration-model.model';
import { IdentityConfigurationModel } from '../../models/identity-configuration-model.model';

@Injectable()
export class EnterpriseSSODataService {
	constructor(private httpService: RQHttpService) {}

	public createOpenId(configurationModel: OpenIdConfigurationModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationModel;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/EnterpriseSSO/CreateOpenId', options).toPromise();
	}

	public updateOpenId(configurationModel: OpenIdConfigurationModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationModel;

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseSSO/UpdateOpenId', options).toPromise();
	}

	public createSaml(configurationModel: SamlConfigurationModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationModel;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/EnterpriseSSO/CreateSaml', options).toPromise();
	}

	public updateSaml(configurationModel: SamlConfigurationModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationModel;

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseSSO/UpdateSaml', options).toPromise();
	}

	public get(options = new HttpOptionsViewModel()): Promise<IdentityConfigurationModel> {
		return this.httpService
			.request<IdentityConfigurationModel>('get', environment.api + '/api/Enterprise/EnterpriseSSO/Get', options)
			.toPromise();
	}

	public getRQRoles(options = new HttpOptionsViewModel()): Promise<string[]> {
		return this.httpService.request<string[]>('get', environment.api + '/api/Enterprise/EnterpriseSSO/GetRQRoles', options).toPromise();
	}
}
