import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LegalEntityUserDataService } from '../../server/services/configuration/legal-entity-user-data.service';
import { LegalEntityType } from '../../server/enums/legal-entity-type.enum';
import { RQ_ROUTES } from '../configs/routes.config';
import { getRouteParams } from '../../share/utilities/route.utility';

export const fidelityOneGuard = async (routeToActivate: ActivatedRouteSnapshot): Promise<boolean> => {
	const router = inject(Router);
	const legalEntityUserDataService = inject(LegalEntityUserDataService);

	const legalEntityId = getRouteParams(routeToActivate).legalEntityId as string;
	const activeLegalEntities = await legalEntityUserDataService.getByUser();

	const legalEntity = activeLegalEntities.find(x => x.id === legalEntityId);

	if (!legalEntity || legalEntity.type !== LegalEntityType.FinancialImpacts) {
		await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
		return false;
	} else {
		return true;
	}
};
