import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisPortfolioLegalEntityModel } from '../../models/analysis-portfolio-legal-entity-model.model';
import { AnalysisLegalEntityFilterModel } from '../../models/analysis-legal-entity-filter-model.model';
import { ImpactVectorSummaryModel } from '../../models/impact-vector-summary-model.model';

@Injectable()
export class EnterpriseAnalysisLegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<AnalysisPortfolioLegalEntityModel[]> {
		return this.httpService
			.request<AnalysisPortfolioLegalEntityModel[]>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisLegalEntity/GetAll',
				options
			)
			.toPromise();
	}

	public getAllActive(options = new HttpOptionsViewModel()): Promise<AnalysisPortfolioLegalEntityModel[]> {
		return this.httpService
			.request<AnalysisPortfolioLegalEntityModel[]>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisLegalEntity/GetAllActive',
				options
			)
			.toPromise();
	}

	public getByFilter(
		filter: AnalysisLegalEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisPortfolioLegalEntityModel[]> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		return this.httpService
			.request<AnalysisPortfolioLegalEntityModel[]>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisLegalEntity/GetByFilter',
				options
			)
			.toPromise();
	}

	public getSummary(analysisId: string, options = new HttpOptionsViewModel()): Promise<ImpactVectorSummaryModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<ImpactVectorSummaryModel>('get', environment.api + '/api/Enterprise/EnterpriseAnalysisLegalEntity/GetSummary', options)
			.toPromise();
	}
}
