import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ScenarioRateOfIncidenceCreateModel } from '../../models/scenario-rate-of-incidence-create-model.model';
import { ScenarioRateOfIncidenceModel } from '../../models/scenario-rate-of-incidence-model.model';
import { ScenarioRateOfIncidenceUpdateModel } from '../../models/scenario-rate-of-incidence-update-model.model';

@Injectable()
export class ScenarioRateOfIncidenceDataService {
	constructor(private httpService: RQHttpService) {}

	public create(scenario: ScenarioRateOfIncidenceCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/ScenarioRateOfIncidence/Create', options).toPromise();
	}

	public getById(legalEntityId: string, scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioRateOfIncidenceModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId
		});

		return this.httpService
			.request<ScenarioRateOfIncidenceModel[]>('get', environment.api + '/api/Scenario/ScenarioRateOfIncidence/GetById', options)
			.toPromise();
	}

	public update(scenario: ScenarioRateOfIncidenceUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('put', environment.api + '/api/Scenario/ScenarioRateOfIncidence/Update', options).toPromise();
	}

	public getDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ScenarioRateOfIncidenceModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ScenarioRateOfIncidenceModel[]>('get', environment.api + '/api/Scenario/ScenarioRateOfIncidence/GetDefaults', options)
			.toPromise();
	}
}
