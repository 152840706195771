import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { IActionResult } from '../../models/iaction-result.model';
import { ScenarioMLDefensesViewModel } from '../../models/scenario-mldefenses-view-model.model';
import { ScenarioMLThreatVectorViewModel } from '../../models/scenario-mlthreat-vector-view-model.model';
import { ScenarioMLBusinessAssetViewModel } from '../../models/scenario-mlbusiness-asset-view-model.model';
import { ScenarioMLCreateViewModel } from '../../models/scenario-mlcreate-view-model.model';
import { ScenarioMLViewModel } from '../../models/scenario-mlview-model.model';

@Injectable()
export class ScenarioMachineLearningDataService {
	constructor(private httpService: RQHttpService) {}

	public updateDefenses(scenario: ScenarioMLDefensesViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = scenario;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMachineLearning/UpdateDefenses', options)
			.toPromise();
	}

	public updateThreatVector(scenario: ScenarioMLThreatVectorViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = scenario;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMachineLearning/UpdateThreatVector', options)
			.toPromise();
	}

	public updateBusinessAsset(scenario: ScenarioMLBusinessAssetViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = scenario;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMachineLearning/UpdateBusinessAsset', options)
			.toPromise();
	}

	public create(scenario: ScenarioMLCreateViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = scenario;

		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Scenario/ScenarioMachineLearning/Create', options)
			.toPromise();
	}

	public getById(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMLViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMLViewModel>('get', environment.api + '/api/Scenario/ScenarioMachineLearning/GetById', options)
			.toPromise();
	}
}
