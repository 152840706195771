import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ExchangeRateModel } from '../../models/exchange-rate-model.model';
import { Void } from '../../models/void.model';
import { ExchangeRateUpdateModel } from '../../models/exchange-rate-update-model.model';

@Injectable()
export class EnterpriseExchangeRateDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<ExchangeRateModel[]> {
		return this.httpService
			.request<ExchangeRateModel[]>('get', environment.api + '/api/Enterprise/EnterpriseExchangeRate/Get', options)
			.toPromise();
	}

	public update(exchangeRate: ExchangeRateUpdateModel, options = new HttpOptionsViewModel()): Promise<Void> {
		options.body = exchangeRate;

		return this.httpService.request<Void>('put', environment.api + '/api/Enterprise/EnterpriseExchangeRate/Update', options).toPromise();
	}
}
