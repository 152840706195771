import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisSyncModel } from '../../models/analysis-sync-model.model';

@Injectable()
export class AnalysisSynchronizationDataService {
	constructor(private httpService: RQHttpService) {}

	public getUnsynced(lastSyncedAnalysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisSyncModel[]> {
		options.params = this.httpService.getHttpParams({
			lastSyncedAnalysisId
		});

		return this.httpService
			.request<AnalysisSyncModel[]>('get', environment.api + '/api/Health/AnalysisSynchronization/GetUnsynced', options)
			.toPromise();
	}

	public update(analysisIds: string[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = analysisIds;

		return this.httpService.request<void>('put', environment.api + '/api/Health/AnalysisSynchronization/Update', options).toPromise();
	}
}
