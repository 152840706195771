import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { RecommendationsConfigParametersModel } from '../../models/recommendations-config-parameters-model.model';

@Injectable()
export class RecommendationsConfigParametersDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<RecommendationsConfigParametersModel> {
		return this.httpService
			.request<RecommendationsConfigParametersModel>('get', environment.api + '/api/Settings/RecommendationsConfigParameters/Get', options)
			.toPromise();
	}
}
