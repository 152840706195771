import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class EnterpriseLossLimitsDataService {
	constructor(private httpService: RQHttpService) {}

	public revertDefaultsForLegalEntities(legalEntityIds: string[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = legalEntityIds;

		return this.httpService
			.request<void>('post', environment.api + '/api/Enterprise/EnterpriseLossLimits/RevertDefaultsForLegalEntities', options)
			.toPromise();
	}
}
