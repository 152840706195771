import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { BusinessApplicationModel } from '../../models/business-application-model.model';
import { Pagination } from '../../models/pagination.model';
import { BusinessApplicationDefinitionModel } from '../../models/business-application-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { BusinessApplicationFilterModel } from '../../models/business-application-filter-model.model';
import { BusinessApplicationFilterOptionModel } from '../../models/business-application-filter-option-model.model';
import { BusinessApplicationCreateViewModel } from '../../models/business-application-create-view-model.model';
import { BusinessApplicationExternalViewModel } from '../../models/business-application-external-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { BusinessApplicationUpdateViewModel } from '../../models/business-application-update-view-model.model';

@Injectable()
export class BusinessApplicationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<BusinessApplicationModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<BusinessApplicationModel[]>('get', environment.api + '/api/Configuration/BusinessApplication/GetByLegalEntity', options)
			.toPromise();
	}

	public getById(
		legalEntityId: string,
		businessApplicationId: string,
		options = new HttpOptionsViewModel()
	): Promise<BusinessApplicationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId
		});

		return this.httpService
			.request<BusinessApplicationModel>('get', environment.api + '/api/Configuration/BusinessApplication/GetById', options)
			.toPromise();
	}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: BusinessApplicationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BusinessApplicationDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BusinessApplicationDefinitionModel>>(
				'get',
				environment.api + '/api/Configuration/BusinessApplication/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<BusinessApplicationFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<BusinessApplicationFilterOptionModel>(
				'get',
				environment.api + '/api/Configuration/BusinessApplication/GetFilterOptions',
				options
			)
			.toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<BusinessApplicationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService
			.request<BusinessApplicationModel>('get', environment.api + '/api/Configuration/BusinessApplication/GetByName', options)
			.toPromise();
	}

	public create(
		legalEntityId: string,
		businessApplication: BusinessApplicationCreateViewModel,
		options = new HttpOptionsViewModel()
	): Promise<BusinessApplicationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = businessApplication;

		return this.httpService
			.request<BusinessApplicationModel>('post', environment.api + '/api/Configuration/BusinessApplication/Create', options)
			.toPromise();
	}

	public createRange(
		legalEntityId: string,
		businessApplications: BusinessApplicationExternalViewModel[],
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = businessApplications;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/BusinessApplication/CreateRange', options)
			.toPromise();
	}

	public updateRange(
		legalEntityId: string,
		businessApplications: BusinessApplicationUpdateViewModel[],
		options = new HttpOptionsViewModel()
	): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = businessApplications;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Configuration/BusinessApplication/UpdateRange', options)
			.toPromise();
	}

	public update(
		legalEntityId: string,
		updatedApplication: BusinessApplicationUpdateViewModel,
		options = new HttpOptionsViewModel()
	): Promise<BusinessApplicationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = updatedApplication;

		return this.httpService
			.request<BusinessApplicationModel>('post', environment.api + '/api/Configuration/BusinessApplication/Update', options)
			.toPromise();
	}

	public delete(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<IActionResult>('delete', environment.api + '/api/Configuration/BusinessApplication/Delete', options)
			.toPromise();
	}

	public clone(
		legalEntityId: string,
		businessApplicationId: string,
		clonedBusinessApplicationName: string,
		options = new HttpOptionsViewModel()
	): Promise<BusinessApplicationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId,
			clonedBusinessApplicationName
		});

		return this.httpService
			.request<BusinessApplicationModel>('post', environment.api + '/api/Configuration/BusinessApplication/Clone', options)
			.toPromise();
	}
}
