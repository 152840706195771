import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { FairValidationError } from '../../models/fair-validation-error.model';
import { FairConfigurationModel } from '../../models/fair-configuration-model.model';

@Injectable()
export class FairDataService {
	constructor(private httpService: RQHttpService) {}

	public clone(
		legalEntityId: string,
		scenarioId: string,
		clonedScenarioName: string,
		options = new HttpOptionsViewModel()
	): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId,
			clonedScenarioName
		});

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/Fair/Clone', options).toPromise();
	}

	public validate(model: FairConfigurationModel, options = new HttpOptionsViewModel()): Promise<FairValidationError[]> {
		options.body = model;

		return this.httpService.request<FairValidationError[]>('post', environment.api + '/api/Scenario/Fair/Validate', options).toPromise();
	}
}
