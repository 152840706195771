export enum EFeatureFlag {
	'BASE_PROBABILITY_2_7' = 0,
	'BASE_PROBABILITY_3_0' = 1,
	'EXPORT_API' = 2,
	'SSO_LOGIN' = 3,
	'ANALYTICS' = 4,
	'NEW_LOSS_MODELS' = 5,
	'NEW_TABLE' = 6,
	'CUSTOM_LOSSES' = 7,
	'SNOWFLAKE' = 8,
	'WIPER' = 9,
	'PEER_ANALYTICS_COMPANY_VISIBLE' = 10,
	'RQ_LOGIN' = 11,
	'PEER_ANALYTICS_FIDELITY_ONE_CARD' = 12,
	'PEER_ANALYTICS_COMPARE_BY_REVENUE_TAB' = 13,
	'HIDE_ENDPOINT_SCORE' = 14,
	'NEW_RECOMMENDATION_CALCULATION' = 15
}
