import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ScenarioModel } from '../../models/scenario-model.model';

@Injectable()
export class EnterpriseScenarioDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioModel>('get', environment.api + '/api/Enterprise/EnterpriseScenario/GetById', options)
			.toPromise();
	}
}
