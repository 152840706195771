import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AnalysisHeatMapDefinitionModel } from '../../models/analysis-heat-map-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { AnalysisHeatMapFilterModel } from '../../models/analysis-heat-map-filter-model.model';
import { AnalysisHeatMapFinancialViewModel } from '../../models/analysis-heat-map-financial-view-model.model';
import { AnalysisHeatMapModel } from '../../models/analysis-heat-map-model.model';
import { AnalysisHeatMapAssignationModel } from '../../models/analysis-heat-map-assignation-model.model';
import { AnalysisHeatMapRanges } from '../../models/analysis-heat-map-ranges.model';

@Injectable()
export class AnalysisHeatMapDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		pagination: PaginationFilter,
		legalEntityId: string,
		filter: AnalysisHeatMapFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisHeatMapDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			legalEntityId,
			filter
		});

		return this.httpService
			.request<Pagination<AnalysisHeatMapDefinitionModel>>('get', environment.api + '/api/Analysis/AnalysisHeatMap/GetPaginated', options)
			.toPromise();
	}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<AnalysisHeatMapFinancialViewModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<AnalysisHeatMapFinancialViewModel[]>('get', environment.api + '/api/Analysis/AnalysisHeatMap/GetByLegalEntityId', options)
			.toPromise();
	}

	public getById(selectionId: string, options = new HttpOptionsViewModel()): Promise<AnalysisHeatMapModel> {
		options.params = this.httpService.getHttpParams({
			selectionId
		});

		return this.httpService
			.request<AnalysisHeatMapModel>('get', environment.api + '/api/Analysis/AnalysisHeatMap/GetById', options)
			.toPromise();
	}

	public update(
		assignation: AnalysisHeatMapAssignationModel,
		filter: AnalysisHeatMapFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Analysis/AnalysisHeatMap/Update', options).toPromise();
	}

	public getLikelihoodRanges(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<AnalysisHeatMapRanges> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<AnalysisHeatMapRanges>('get', environment.api + '/api/Analysis/AnalysisHeatMap/GetLikelihoodRanges', options)
			.toPromise();
	}
}
