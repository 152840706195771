import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { BaExploitabilityLibraryViewModel } from '../../models/ba-exploitability-library-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { BaExploitabilityLibraryFilterViewModel } from '../../models/ba-exploitability-library-filter-view-model.model';
import { BaExploitabilityLibraryFilterOptionViewModel } from '../../models/ba-exploitability-library-filter-option-view-model.model';
import { BaLibraryAssignViewModel } from '../../models/ba-library-assign-view-model.model';
import { BaLibraryUpdateViewModel } from '../../models/ba-library-update-view-model.model';

@Injectable()
export class BaExploitabilityLibraryDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		businessApplicationId: string,
		pagination: PaginationFilter,
		filter: BaExploitabilityLibraryFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BaExploitabilityLibraryViewModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BaExploitabilityLibraryViewModel>>(
				'get',
				environment.api + '/api/Configuration/BaExploitabilityLibrary/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<BaExploitabilityLibraryFilterOptionViewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<BaExploitabilityLibraryFilterOptionViewModel>(
				'get',
				environment.api + '/api/Configuration/BaExploitabilityLibrary/GetFilters',
				options
			)
			.toPromise();
	}

	public update(
		legalEntityId: string,
		assignationViewModel: BaLibraryAssignViewModel,
		filter: BaExploitabilityLibraryFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter
		});

		options.body = assignationViewModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/BaExploitabilityLibrary/Update', options)
			.toPromise();
	}

	public updateRange(
		legalEntityId: string,
		updateViewModels: BaLibraryUpdateViewModel[],
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = updateViewModels;

		return this.httpService
			.request<void>('put', environment.api + '/api/Configuration/BaExploitabilityLibrary/UpdateRange', options)
			.toPromise();
	}
}
