import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { TechnicalRiskConfigurationViewModel } from '../../models/technical-risk-configuration-view-model.model';

@Injectable()
export class TechnicalRiskConfigurationDataService {
	constructor(private httpService: RQHttpService) {}

	public getTechnicalRiskConfiguration(options = new HttpOptionsViewModel()): Promise<TechnicalRiskConfigurationViewModel[]> {
		return this.httpService
			.request<TechnicalRiskConfigurationViewModel[]>(
				'get',
				environment.api + '/api/Settings/TechnicalRiskConfiguration/GetTechnicalRiskConfiguration',
				options
			)
			.toPromise();
	}
}
