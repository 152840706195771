import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { CweUpdateViewModel } from '../../models/cwe-update-view-model.model';

@Injectable()
export class CweDataService {
	constructor(private httpService: RQHttpService) {}

	public updateRange(updateModels: CweUpdateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModels;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/Cwe/UpdateRange', options).toPromise();
	}
}
