import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { CustomLossParameterViewModel } from '../../models/custom-loss-parameter-view-model.model';
import { LossModelFilterModel } from '../../models/loss-model-filter-model.model';
import { LossParameterViewModel } from '../../models/loss-parameter-view-model.model';
import { CustomLossFormulaViewModel } from '../../models/custom-loss-formula-view-model.model';
import { EnterpriseLossFormulaInputModel } from '../../models/enterprise-loss-formula-input-model.model';
import { OperationResult } from '../../models/operation-result.model';
import { CustomLossFormulaUpdateViewModel } from '../../models/custom-loss-formula-update-view-model.model';
import { CustomLossFormulaCreateViewModel } from '../../models/custom-loss-formula-create-view-model.model';

@Injectable()
export class EnterpriseLossFormulaDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(filter: LossModelFilterModel, options = new HttpOptionsViewModel()): Promise<CustomLossParameterViewModel[]> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		return this.httpService
			.request<CustomLossParameterViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseLossFormula/GetAll', options)
			.toPromise();
	}

	public getById(customModelId: string, options = new HttpOptionsViewModel()): Promise<LossParameterViewModel[]> {
		options.params = this.httpService.getHttpParams({
			customModelId
		});

		return this.httpService
			.request<LossParameterViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseLossFormula/GetById', options)
			.toPromise();
	}

	public calculate(input: EnterpriseLossFormulaInputModel, options = new HttpOptionsViewModel()): Promise<CustomLossFormulaViewModel> {
		options.body = input;

		return this.httpService
			.request<CustomLossFormulaViewModel>('post', environment.api + '/api/Enterprise/EnterpriseLossFormula/Calculate', options)
			.toPromise();
	}

	public update(updateModel: CustomLossFormulaUpdateViewModel, options = new HttpOptionsViewModel()): Promise<OperationResult> {
		options.body = updateModel;

		return this.httpService
			.request<OperationResult>('put', environment.api + '/api/Enterprise/EnterpriseLossFormula/Update', options)
			.toPromise();
	}

	public create(createModel: CustomLossFormulaCreateViewModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/EnterpriseLossFormula/Create', options).toPromise();
	}
}
