import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ScenarioEnterpriseControlsCreateModel } from '../../models/scenario-enterprise-controls-create-model.model';
import { ScenarioEnterpriseControlsUpdateModel } from '../../models/scenario-enterprise-controls-update-model.model';
import { EnterpriseProfileModel } from '../../models/enterprise-profile-model.model';

@Injectable()
export class ScenarioEnterpriseControlDataService {
	constructor(private httpService: RQHttpService) {}

	public create(scenario: ScenarioEnterpriseControlsCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService
			.request<string>('post', environment.api + '/api/Scenario/ScenarioEnterpriseControl/Create', options)
			.toPromise();
	}

	public update(scenario: ScenarioEnterpriseControlsUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('put', environment.api + '/api/Scenario/ScenarioEnterpriseControl/Update', options).toPromise();
	}

	public getById(scenarioId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseProfileModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<EnterpriseProfileModel>('get', environment.api + '/api/Scenario/ScenarioEnterpriseControl/GetById', options)
			.toPromise();
	}
}
