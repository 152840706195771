import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { LossExposureMagnitudeModel } from '../../models/loss-exposure-magnitude-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LossExposureMagnitudeFilterModel } from '../../models/loss-exposure-magnitude-filter-model.model';
import { LossExposureMagnitudeFilterOptionModel } from '../../models/loss-exposure-magnitude-filter-option-model.model';
import { LossExposureMagnitudeCreateModel } from '../../models/loss-exposure-magnitude-create-model.model';
import { LossExposureMagnitudeUpdateModel } from '../../models/loss-exposure-magnitude-update-model.model';

@Injectable()
export class LossExposureMagnitudeDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		lossExposureId: string,
		pagination: PaginationFilter,
		filter: LossExposureMagnitudeFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LossExposureMagnitudeModel>> {
		options.params = this.httpService.getHttpParams({
			lossExposureId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<LossExposureMagnitudeModel>>('get', environment.api + '/api/TPConfig/LossExposureMagnitude/GetPaginated', options)
			.toPromise();
	}

	public count(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService.request<number>('get', environment.api + '/api/TPConfig/LossExposureMagnitude/Count', options).toPromise();
	}

	public getFilterOptions(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureMagnitudeFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureMagnitudeFilterOptionModel>(
				'get',
				environment.api + '/api/TPConfig/LossExposureMagnitude/GetFilterOptions',
				options
			)
			.toPromise();
	}

	public getById(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<LossExposureMagnitudeModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<LossExposureMagnitudeModel>('get', environment.api + '/api/TPConfig/LossExposureMagnitude/GetById', options)
			.toPromise();
	}

	public create(createModel: LossExposureMagnitudeCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureMagnitude/Create', options).toPromise();
	}

	public update(updateModel: LossExposureMagnitudeUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/LossExposureMagnitude/Update', options).toPromise();
	}

	public delete(id: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			id
		});

		return this.httpService.request<void>('delete', environment.api + '/api/TPConfig/LossExposureMagnitude/Delete', options).toPromise();
	}
}
