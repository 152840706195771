import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { ControlFrameworkDescriptionModel } from '../../models/control-framework-description-model.model';

@Injectable()
export class ControlFrameworkDescriptionDataService {
	constructor(private httpService: RQHttpService) {}

	public getByFramework(controlFrameworkId: string, options = new HttpOptionsViewModel()): Promise<ControlFrameworkDescriptionModel[]> {
		options.params = this.httpService.getHttpParams({
			controlFrameworkId
		});

		return this.httpService
			.request<ControlFrameworkDescriptionModel[]>(
				'get',
				environment.api + '/api/Configuration/ControlFrameworkDescription/GetByFramework',
				options
			)
			.toPromise();
	}
}
