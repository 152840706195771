import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { TPScenarioDefinitionModel } from '../../models/tpscenario-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { TPScenarioFilterModel } from '../../models/tpscenario-filter-model.model';
import { TPScenarioFilterOptionModel } from '../../models/tpscenario-filter-option-model.model';
import { TpScenarioViewModel } from '../../models/tp-scenario-view-model.model';

@Injectable()
export class TPScenarioLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: TPScenarioFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<TPScenarioDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<TPScenarioDefinitionModel>>('get', environment.api + '/api/TPAnalysis/TPScenarioLoss/GetPaginated', options)
			.toPromise();
	}

	public getFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<TPScenarioFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<TPScenarioFilterOptionModel>('get', environment.api + '/api/TPAnalysis/TPScenarioLoss/GetFilters', options)
			.toPromise();
	}

	public export(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/TPAnalysis/TPScenarioLoss/Export', options).toPromise();
	}

	public getByAnalysisId(thirdPartyAnalysisId: string, options = new HttpOptionsViewModel()): Promise<TpScenarioViewModel[]> {
		options.params = this.httpService.getHttpParams({
			thirdPartyAnalysisId
		});

		return this.httpService
			.request<TpScenarioViewModel[]>('get', environment.api + '/api/TPAnalysis/TPScenarioLoss/GetByAnalysisId', options)
			.toPromise();
	}
}
