import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AnalysisEndpointViewModel } from '../../models/analysis-endpoint-view-model.model';
import { AnalysisEndpointFilterViewModel } from '../../models/analysis-endpoint-filter-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { AnalysisEndpointFilterOptionViewModel } from '../../models/analysis-endpoint-filter-option-view-model.model';

@Injectable()
export class AnalysisEndpointDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		analysisId: string,
		filterViewModel: AnalysisEndpointFilterViewModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisEndpointViewModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filterViewModel,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisEndpointViewModel>>('get', environment.api + '/api/Analysis/AnalysisEndpoint/GetPaginated', options)
			.toPromise();
	}

	public getFilters(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisEndpointFilterOptionViewModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisEndpointFilterOptionViewModel>('get', environment.api + '/api/Analysis/AnalysisEndpoint/GetFilters', options)
			.toPromise();
	}
}
