import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AnalysisCveViewModel } from '../../models/analysis-cve-view-model.model';
import { AnalysisCveFilterViewModel } from '../../models/analysis-cve-filter-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { AnalysisCveFilterOptionViewModel } from '../../models/analysis-cve-filter-option-view-model.model';

@Injectable()
export class AnalysisCveDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		analysisId: string,
		filterViewModel: AnalysisCveFilterViewModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisCveViewModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filterViewModel,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisCveViewModel>>('get', environment.api + '/api/Analysis/AnalysisCve/GetPaginated', options)
			.toPromise();
	}

	public getFilters(
		analysisId: string,
		analysisEndpointId: string,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisCveFilterOptionViewModel> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			analysisEndpointId
		});

		return this.httpService
			.request<AnalysisCveFilterOptionViewModel>('get', environment.api + '/api/Analysis/AnalysisCve/GetFilters', options)
			.toPromise();
	}
}
