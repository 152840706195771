import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../../environments/environment';
import { AnalysisApplicationModel } from '../../models/analysis-application-model.model';

@Injectable()
export class ScenarioAnalysisApplicationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<AnalysisApplicationModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<AnalysisApplicationModel>('get', environment.api + '/api/Scenario/ScenarioAnalysisApplication/GetByScenarioId', options)
			.toPromise();
	}
}
